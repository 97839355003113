import React, { useEffect, useContext } from "react";
//import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { isMobile } from "../../js/designManager";
//IMAGES AND ICONS
import StoreIcon from "@mui/icons-material/Store";
import PeopleIcon from "@mui/icons-material/People";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import BarChartIcon from "@mui/icons-material/BarChart";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CameraOutdoorIcon from "@mui/icons-material/CameraOutdoor";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import CampaignIcon from "@mui/icons-material/Campaign";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LinkIcon from "@mui/icons-material/Link";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import StarIcon from "@mui/icons-material/Star";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import SpeedIcon from "@mui/icons-material/Speed";
import CodeIcon from "@mui/icons-material/Code";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import BugReportIcon from "@mui/icons-material/BugReport";
import HomeIcon from "@mui/icons-material/Home";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import VideocamIcon from "@mui/icons-material/Videocam";
//MATERIAL UI COMPONENT IMPORTS
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

// ---------- CONST -----------
export const drawerWidth = 240;
export const drawerWidthMobile = 80;
export const cockpitViews = [
  {
    name: "portal",
    label: "Portail",
    views: [
      {
        name: "portal_restaurant",
        label: "Accueil",
        allowed: ["admin", "super admin", "portal"],
        icon: <HomeIcon />,
      },
    ],
  },
  {
    name: "admin",
    label: "Admin",
    views: [
      {
        name: "admin_integrations",
        label: "Mes intégrations",
        allowed: ["admin", "super admin"],
        icon: <LinkIcon />,
      },
      {
        name: "admin_restaurants",
        label: "Tagging",
        allowed: ["admin", "super admin"],
        icon: <StoreIcon />,
      },
      {
        name: "admin_settings_restaurants",
        label: "Mes restaurants",
        allowed: [
          "admin",
          "super admin",
          "der",
          "dm/dso",
          "coach",
          "director",
          "franchise",
        ],
        icon: <StoreIcon />,
      },
      {
        name: "admin_anomalies",
        label: "Anomalies",
        allowed: ["admin", "super admin", "marketing"],
        icon: <RunningWithErrorsIcon />,
      },
      {
        name: "admin_users",
        label: "Mes utilisateurs",
        allowed: ["admin", "super admin", "marketing"],
        icon: <PeopleIcon />,
      },
      {
        name: "admin_dev",
        label: "Développeur",
        allowed: ["admin", "super admin"],
        icon: <CodeIcon />,
      },
    ],
  },
  /*
  {
    name: "lab",
    label: "Lab",
    views: [
      {
        name: "lab_experiments",
        label: "Mes expériences",
        allowed:["admin","super admin"],
        icon: <ScienceIcon />,
      },
      {
        name: "lab_results",
        label: "Mes résultats",
        allowed:["admin","super admin"],
        icon: <BarChartIcon />,
      },
    ],
  },
  */
  {
    name: "radar",
    label: "Radar",
    views: [
      {
        name: "radar_dashboard",
        label: "Dashboard",
        allowed: ["admin", "super admin", "bi", "marketing", "cgo"],
        icon: <SpeedIcon />,
      },
      {
        name: "radar_marketshares",
        label: "Parts de marché",
        allowed: [
          "admin",
          "super admin",
          "bi",
          "marketing",
          "cgo",
          "dm/dso",
          "der",
          "coach",
          //"director",
          //"franchise",
        ],
        icon: <VideocamIcon />,
      },
      {
        name: "radar_health",
        label: "Healthboard",
        allowed: [
          "admin",
          "super admin",
          "bi",
          "marketing",
          "der",
          "cgo",
          "dm/dso",
          "coach",
          "director",
          "franchise",
        ],
        icon: <HealthAndSafetyIcon />,
      },
      {
        name: "radar_competitors",
        label: "Mes concurrents",
        allowed: ["admin", "super admin", "bi"],
        icon: <VideocamIcon />,
      },
      {
        name: "radar_leaderboard",
        label: "Leaderboard",
        allowed: ["admin", "super admin", "cgo"],
        icon: <MilitaryTechIcon />,
      },
      {
        name: "radar_customers",
        label: "Mes clients",
        allowed: ["admin", "super admin"],
        icon: <PeopleOutlineIcon />,
      },
      {
        name: "radar_overview",
        label: "Mes finances",
        allowed: ["admin", "super admin", "bi", "marketing", "cgo"],
        icon: <BarChartIcon />,
      },
      {
        name: "radar_conversion",
        label: "Ma conversion",
        allowed: ["admin", "super admin", "bi", "marketing", "cgo"],
        icon: <FilterAltIcon />,
      },
      {
        name: "radar_ranking",
        label: "Ma visibilité",
        allowed: [
          "admin",
          "super admin",
          "bi",
          "marketing",
          "cgo",
          "der",
          "dm/dso",
          "coach",
          "director",
          "franchise",
        ],
        icon: <VisibilityIcon />,
      },
      {
        name: "radar_sponsoring",
        label: "Mon sponsoring",
        allowed: [
          "admin",
          "super admin",
          /* "bi",
          "marketing",
          "cgo",
          "der",
          "dm/dso",
          "coach",
          "director",
          "franchise",*/
        ],
        icon: <CampaignIcon />,
      },
      {
        name: "radar_downtimes",
        label: "Mes heures de connexion",
        allowed: [
          "admin",
          "super admin",
          "bi",
          "marketing",
          "cgo",
          "der",
          "dm/dso",
          "coach",
          "director",
          "franchise",
        ],
        icon: <TimelapseIcon />,
      },
      {
        name: "radar_scrappings",
        label: "Mes scrappings",
        allowed: ["admin", "super admin"],
        icon: <CurrencyExchangeIcon />,
      },

      {
        name: "radar_orders",
        label: "Mes commandes",
        allowed: ["admin", "super admin"],
        icon: <FastfoodIcon />,
      },
      {
        name: "radar_ratings",
        label: "Mes avis",
        allowed: ["admin", "super admin"],
        icon: <StarIcon />,
      },
    ],
  },
  {
    name: "magnet",
    label: "Magnet",
    views: [
      {
        name: "magnet_macroCampaigns",
        label: "Mes campagnes",
        allowed: ["admin", "super admin"],
        icon: <QuestionAnswerIcon />,
      },
      {
        name: "magnet_performance",
        label: "Performance",
        allowed: ["admin", "super admin"],
        icon: <AutoGraphIcon />,
      },
      {
        name: "magnet_restaurants",
        label: "Réponse automatique aux avis",
        allowed: [
          "admin",
          "super admin",
          "der",
          "dm/dso",
          "coach",
          "director",
          "franchise",
        ],
        icon: <QuestionAnswerIcon />,
      },
    ],
  },
  {
    name: "jetpack",
    label: "Jetpack",
    views: [
      {
        name: "jetpack_campaigns_admin",
        label: "Mes campagnes",
        allowed: ["admin", "super admin", "marketing"],
        icon: <CampaignIcon />,
      },
      {
        name: "jetpack_campaigns_platforms",
        label: "Campagnes restaurants",
        allowed: ["admin", "uber", "deliveroo"],
        icon: <CampaignIcon />,
      },
      {
        name: "jetpack_performance_admin",
        label: "Performance",
        allowed: ["admin", "super admin"],
        icon: <AutoGraphIcon />,
      },
      {
        name: "jetpack_campaigns_restaurant",
        label: "Mes campagnes",
        allowed: ["der", "dm/dso", "coach", "director", "franchise"],
        icon: <CampaignIcon />,
      },
      {
        name: "jetpack_performance_restaurant",
        label: "Performance",
        allowed: [
          "admin",
          "super admin",
          "der",
          "dm/dso",
          "coach",
          "director",
          "franchise",
        ],
        icon: <AutoGraphIcon />,
      },
    ],
  },
  {
    name: "butler",
    label: "Butler",
    views: [
      {
        name: "butler_product",
        label: "Mes Produits",
        allowed: ["admin", "super admin"],
        icon: <RamenDiningIcon />,
      },
      {
        name: "butler_menus",
        label: "Mes Menus",
        allowed: ["admin", "super admin"],
        icon: <MenuBookIcon />,
      },
    ],
  },
];

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "10px",
  },
  listItem: {
    borderRadius: "10px !important",
  },
  active: {
    backgroundColor: `${theme.palette.primary.light}!important`,
  },
}));

function CockpitDrawer(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const {
    user,
    cockpitDrawerOpen,
    setCockpitDrawerOpen,
    cockpitView,
    setCockpitView,
  } = useContext(UserContext);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    switch (user.status) {
      case "admin":
        setCockpitView("admin_integrations");
        break;
      case "super admin":
        setCockpitView("admin_integrations");
        break;
      case "uber":
        setCockpitView("jetpack_campaigns_platforms");
        break;
      case "marketing":
        setCockpitView("admin_users");
        break;
      case "cgo":
        setCockpitView("radar_dashboard");
        break;
      case "der":
      case "dm/dso":
      case "coach":
      case "director":
      case "franchise":
        setCockpitView("jetpack_campaigns_restaurant");
        break;
      default:
        setCockpitView("");
    }
  }, [user, setCockpitView]);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <Drawer
      anchor="left"
      variant="persistent"
      transitionDuration={{ enter: 500, exit: 500 }}
      //onClose={handleToggle}
      ModalProps={{
        keepMounted: true,
      }}
      open={cockpitDrawerOpen}
      PaperProps={{
        sx: {
          width: isMobile() ? drawerWidthMobile : drawerWidth,
          marginTop: "65px",
          zIndex: 10,
        },
      }}
    >
      {cockpitViews.map((Category) => {
        var containView = false;
        Category.views.map((view) => {
          if (view.allowed.indexOf(user.status) >= 0) {
            containView = true;
          }
        });
        if (!containView) {
          return <div key={Category.name}></div>;
        }
        return (
          <React.Fragment key={Category.name}>
            <div className={classes.wrapper}>
              <Typography variant="p" color="primary">
                {Category.label}
              </Typography>
              <List>
                {Category.views.map((view, index) => {
                  if (view.allowed.indexOf(user.status) >= 0) {
                    return (
                      <ListItem
                        button
                        key={Category.name + "-" + view.name}
                        className={`${classes.listItem} ${
                          cockpitView === view.name ? classes.active : ""
                        }`}
                        onClick={() => {
                          setCockpitView(view.name);
                        }}
                      >
                        <ListItemIcon>{view.icon}</ListItemIcon>
                        {isMobile() ? (
                          <React.Fragment></React.Fragment>
                        ) : (
                          <ListItemText primary={view.label} />
                        )}
                      </ListItem>
                    );
                  } else {
                    return <div key={Category.name + "-" + view.name}></div>;
                  }
                })}
              </List>
            </div>
            <Divider></Divider>
          </React.Fragment>
        );
      })}
      <div className={classes.wrapper}>
        <List>
          <ListItem
            button
            key={"close"}
            className={`${classes.listItem}`}
            onClick={() => {
              setCockpitDrawerOpen(!cockpitDrawerOpen);
            }}
          >
            <ListItemIcon>
              <FullscreenIcon />
            </ListItemIcon>
            {isMobile() ? (
              <React.Fragment></React.Fragment>
            ) : (
              <ListItemText primary={"Mode plein écran"} />
            )}
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}

CockpitDrawer.propTypes = {};

export default CockpitDrawer;
